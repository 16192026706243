import Vue from 'vue'
import moment from 'moment'
import { isNullOrUndefined } from '../utils'

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c
      ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16))
}

export function state() {
  return {
    open: [],
    currentTicketId: null,
    currentCustomer: {
      address: null,
      amount_owed: null,
      avatar: null,
      created_at: null,
      email: '----',
      first_name: '----',
      id: 0,
      last_name: '----',
      name: '----',
      open_orders: 0,
      phone: '(---) -------',
      total_orders: 0,
      updated_at: null,
    },
    amountOwed: {},
    selectedItem: null,
  }
}

export const mutations = {
  PUSH_NEW_TICKET: (state, ticket) => state.open.push(ticket),
  SET_CURRENT_TICKET_ID: (state, id) => (state.currentTicketId = id),
  SET_SELECTED_ITEM: (state, item) => (state.selectedItem = item),
  SET_OPEN_TICKET: (state, tickets) => {
    Vue.set(state, 'open', tickets)
  },
  SET_TICKET_PROPERTY: (state, { ticket, key, value }) => {
    state.open = state.open.map((t) => {
      if (t.id === ticket.id)
        t[key] = value

      return t
    })
  },
  SET_CURRENT_CUSTOMER: (state, customer) => (state.currentCustomer = customer),
  ADD_GARMENTS: (state, { ticket, item }) => ticket.items.push(item),
  REMOVE_GARMENT: (state, { id, index }) => {
    const tickets = state.open.map((ticket) => {
      if (ticket.id === id)
        ticket.items = ticket.items.filter((i, pos) => pos !== index)

      return ticket
    })
    Vue.set(state, 'open', tickets)
  },
  SET_CURRENT_TICKET: (state, ticketID) => (state.currentTicketId = ticketID),
  REMOVE_TICKET: (state, ticketID) => {
    const tickets = state.open.filter(ticket => ticket.id !== ticketID)
    Vue.set(state, 'open', tickets)
  },
  REMOVE_CUSTOMER_TICKETS: (state, customerID) => {
    const tickets = state.open.filter(
      ticket => ticket.customer.id !== customerID,
    )
    Vue.set(state, 'open', tickets)
  },
  SET_TICKET_ITEM: (state, { ticket, index, item }) => {
    state.open = state.open.map((t) => {
      if (t.id === ticket.id)
        t.items[index] = item

      return t
    })
  },
  SET_TICKET_ITEM_PROPERTY: (state, { ticket, index, key, value, addOn }) => {
    if (addOn)
      ticket.items[0].addon = { ...addOn }

    state.open = state.open.map((t) => {
      if (t.id === ticket.id)
        t.items[index][key] = value

      return t
    })
  },
  UPDATE_CUSTOMER_AMOUNT_OWED: (state, { customerId, amountOwed }) => {
    Vue.set(state.amountOwed, customerId, amountOwed)
  },
  CLEAR_TICKETS: (state) => {
    state.open = []
  },
}

export const actions = {
  /** */
  async setCurrentCustomer({ commit, dispatch, getters }, id) {
    if (Number.parseInt(id) === 0) {
      dispatch('ui/openCustomerSearchModal', null, { root: true })
      return
    }

    const customer = await dispatch('customers/fetchItem', id, { root: true })
    if (!customer) {
      const error = new Error('Customer not found!')
      error.statusCode = 404
      throw error
    }
    commit('SET_CURRENT_CUSTOMER', customer)
    if (!getters.openTickets.length)
      await dispatch('openNewTicket', { customer })

    const { id: ticketId } = getters.openTickets[0]
    commit('SET_CURRENT_TICKET_ID', ticketId)
    commit('SET_CURRENT_TICKET', ticketId)

    return customer
  },

  /** */
  openNewTicket({ commit }, data = {}) {
    const { customer, service } = data
    const method = 'use_hangers'

    const droppedAt = moment().format('YYYY-MM-DD HH:mm:ss')
    const dueDate = moment(droppedAt).add(1, 'days').format('YYYY-MM-DD HH:mm:ss')

    const ticket = {
      id: uuidv4(),
      service,
      customer,
      method,
      items: [],
      dropped_at: droppedAt,
      due_date: dueDate,
    }
    commit('PUSH_NEW_TICKET', ticket)
    return ticket
  },

  /** */
  clearOpenedTickets({ commit }) {
    commit('CLEAR_TICKETS')
  },

  /** */
  setTicketMethod({ commit, getters }, method) {
    const ticket = getters.currentTicket
    commit('SET_TICKET_PROPERTY', { ticket, key: 'method', value: method })
  },

  /** */
  async setTicketType({ commit, getters, dispatch, state }, service) {
    let ticket = getters.currentTicket
    if (isNullOrUndefined(ticket)) {
      await dispatch('openNewTicket', {
        customer: state.currentCustomer,
        service,
      }).then(t => (ticket = t))
      commit('SET_CURRENT_TICKET', ticket.id)
    }
    commit('SET_TICKET_PROPERTY', { ticket, key: 'service', value: service })
  },

  /** */
  setTicketKeyValue({ commit, getters }, { key, value }) {
    const ticket = getters.currentTicket
    commit('SET_TICKET_PROPERTY', { ticket, key, value })
  },

  /** */
  setCurrentTicket({ commit }, id) {
    commit('SET_CURRENT_TICKET', id)
  },

  /** */
  async addGarments(
    { commit, getters, dispatch, rootGetters },
    { item, qty, color, pattern, coupon },
  ) {
    const itemsPerTicket = await rootGetters.currentLocation.items_per_ticket
    const ticket = await getters.currentTicket
    const { totalPieces } = ticket

    if (itemsPerTicket > totalPieces) {
      await commit('ADD_GARMENTS', {
        ticket,
        item: { ...item, qty, color, pattern, coupon },
      })

      return ticket.items.length - 1
    }
    else {
      const { customer, service } = ticket
      await dispatch('openNewTicket', { customer, service }).then(({ id }) => {
        dispatch('setCurrentTicket', id).then(() => {
          dispatch('addGarments', { item, qty, color, pattern, coupon })
        })
      })

      return 0
    }
  },

  /** */
  removeGarment({ commit, getters }, index) {
    const { id } = getters.currentTicket
    commit('REMOVE_GARMENT', { id, index })
  },

  /** */
  removeTicket({ commit, getters, dispatch }, id) {
    const total = getters.openTickets.length
    commit('REMOVE_TICKET', id)
    const currentTicketId = getters.openTickets[total - 2].id
    dispatch('setCurrentTicket', currentTicketId)
  },

  /** */
  clearCustomerTickets(
    { commit, getters, state, dispatch },
    { id, redirect = true, openSearch = false, removeCustomer = false },
  ) {
    const isActive = state.currentCustomer.id === id
    const customers = getters.recentCustomers
    // calculates the next customer index after closing this ${id} one
    let index = customers.findIndex(customer => customer.id === id)
    index = Math.max(0, index + 1)
    index = index >= customers.length ? index - 2 : index

    const exec = () => {
      commit('REMOVE_CUSTOMER_TICKETS', id)
      if (customers.length <= 1) {
        if (removeCustomer)
          commit('SET_CURRENT_CUSTOMER', null)
        commit('SET_CURRENT_TICKET', null)
        if (openSearch)
          return dispatch('ui/openCustomerSearchModal', null, { root: true })
      }
    }

    let route = customers.length > 1 ? `/pos/${customers[index].id}` : '/pos/0'
    route = redirect === true ? route : redirect

    if (redirect && isActive)
      this.$router.push(route, exec)
    else exec()
  },

  /** */
  setItemAddon({ commit, getters }, { addOn, index }) {
    const ticket = getters.currentTicket

    commit('SET_TICKET_ITEM_PROPERTY', {
      ticket,
      index,
      key: 'addon',
      value: addOn,
    })
  },

  /** */
  setTicketItemProperty({ commit, getters }, { index, key, value }) {
    const ticket = getters.currentTicket
    commit('SET_TICKET_ITEM_PROPERTY', {
      ticket,
      index,
      key,
      value,
    })
  },

  async validateAndSplitTicketItems({ getters, rootGetters, dispatch }) {
    const { items, customer, service } = getters.currentTicket
    const itemsPerTicket = await rootGetters.currentLocation.items_per_ticket

    let maxTicketQty = itemsPerTicket

    items.sort((a, b) => a.qty - b.qty).map(async (item) => {
      const remainingQty = maxTicketQty - item.qty

      if (remainingQty >= 0) {
        maxTicketQty = remainingQty
        return item
      }

      item.qty = maxTicketQty

      const { color, pattern } = item
      let exceedQty = Math.abs(remainingQty)

      if (exceedQty > itemsPerTicket) {
        while (exceedQty > itemsPerTicket) {
          exceedQty -= itemsPerTicket

          await dispatch('openNewTicket', { customer, service }).then(({ id }) => {
            dispatch('setCurrentTicket', id).then(() => {
              dispatch('addGarments', { item, qty: itemsPerTicket, color, pattern })
            })
          })
        }

        await dispatch('openNewTicket', { customer, service }).then(({ id }) => {
          dispatch('setCurrentTicket', id).then(() => {
            dispatch('addGarments', { item, qty: exceedQty, color, pattern })
          })
        })
      }
      else {
        await dispatch('openNewTicket', { customer, service }).then(({ id }) => {
          dispatch('setCurrentTicket', id).then(() => {
            dispatch('addGarments', { item, qty: exceedQty, color, pattern })
          })
        })
      }

      return item
    })
  },

  async mergeGarmentItems({ state }) {
    if (state.open.length === 0) {
      return
    }

    state.open = await Promise.all(state.open.map(async (t) => {
      if (t.items.length === 0) {
        return t
      }

      const itemMap = new Map()

      t.items.forEach((item) => {
        let key = `${item.id}-${item.color}-${item.pattern}`
        if (item.addon) {
          key = `${key}-${item.addon.name}`
        }

        if (itemMap.has(key)) {
          const existingItem = itemMap.get(key)
          existingItem.qty += item.qty
        }
        else {
          itemMap.set(key, { ...item })
        }
      })

      // Replace items with merged results
      t.items = Array.from(itemMap.values())
      return t
    }))
  },

  async reloadTickets({ state, rootGetters, commit }) {
    const items = rootGetters['items/storeItems']
    const addons = rootGetters['items/storeAddons']

    if (items.length === 0 || state.open.length === 0) {
      return
    }

    const tickets = state.open.map((t) => {
      if (t.items.length === 0) {
        return t
      }

      const updatedItems = t.items.map((item) => {
        if (!item) {
          return item
        }

        const foundItem = items.find(s => s.id === item.id)

        let foundAddon = null
        if (item && item.addon) {
          foundAddon = addons.find(s => s.id === item.addon.id)
        }

        if (foundItem) {
          return {
            ...item,
            ...foundItem,
            addon: foundAddon,
          }
        }

        return foundItem
      })

      return {
        ...t,
        items: updatedItems,
      }
    })

    commit('SET_OPEN_TICKET', tickets)
  },

  async updateGarment({ commit, getters }, data) {
    const ticket = await getters.currentTicket

    const { item, index: ticketIndex, qty, pattern, color, addon } = data

    const updatedItem = {
      ...item,
      qty,
      pattern,
      color,
      addon,
    }

    commit('SET_TICKET_ITEM', {
      ticket,
      index: ticketIndex,
      item: updatedItem,
    })
  },

  /** */
  updateTicketItemQuantity({ commit, getters }, { index, qty }) {
    const ticket = getters.currentTicket
    // const item = ticket.items[index]

    // if (item.qty >= qty) {
    commit('SET_TICKET_ITEM_PROPERTY', {
      ticket,
      index,
      key: 'qty',
      value: qty,
    })
    // }
    // else {
    //   commit('SET_TICKET_ITEM_PROPERTY', {
    //     ticket,
    //     index,
    //     key: 'qty',
    //     value: 0,
    //   })
    //   const { color, pattern } = item
    //   dispatch('addGarments', { item, qty, color, pattern })
    // }
  },

  /** */
  updateCustomerAmountOwed({ commit }, { customerId, amountOwed }) {
    commit('UPDATE_CUSTOMER_AMOUNT_OWED', {
      customerId,
      amountOwed,
    })
  },

}

export const getters = {
  openTickets: (state) => {
    if (!state.currentCustomer)
      return []
    return state.open
      .filter(ticket => ticket.customer && ticket.customer.id === state.currentCustomer.id)
      .map((ticket) => {
        const totalPieces = ticket.items.reduce((pieces, item) => {
          if (isNullOrUndefined(item))
            return pieces
          return pieces + item.qty
        }, 0)
        const subTotal = ticket.items.reduce((subTotal, item) => {
          if (isNullOrUndefined(item))
            return subTotal

          const addOns = item.addon ? item.addon.price : 0
          const total = item.qty * item.price + addOns

          // if (!item.coupon) {
          //   return subTotal + total
          // }

          // const { type, discount_value } = item.coupon

          // if (type === 'fixed') {
          //   return (subTotal + total) - (discount_value / 100)
          // }

          // if (type === 'percentage') {
          //   return (subTotal + total) * (discount_value / 100)
          // }

          return subTotal + total
        }, 0)
        const dueDate = calculateDueDate(ticket)
        const tax = ticket.items.reduce((tax, item) => {
          if (isNullOrUndefined(item))
            return tax

          return tax + (item.tax * item.qty)
        }, 0)

        return {
          ...ticket,
          due_date: dueDate,
          totalPieces,
          coupons: 0,
          subTotal,
          tax,
          total: subTotal + tax,
        }
      })
  },
  currentTicket(state, getters) {
    return getters.openTickets
      .filter(ticket => ticket.id === state.currentTicketId)
      .pop()
  },
  recentCustomers(state) {
    const addedCustomers = state.open
      .filter(ticket => ticket.customer !== null)
      .map(ticket => ({
        ...ticket.customer,
      }))
    const customers = []
    addedCustomers.forEach((customer) => {
      const userExists = customers.some(c => c.id === customer.id)
      if (!userExists)
        customers.push(customer)
    })
    return customers
  },
  getOwedAmount: state => (customer) => {
    const amount = state.amountOwed[customer.id]
    if (amount || amount === 0)
      return amount

    return customer.amount_owed
  },
  findGarment: (state, getters) => ({ item, color, pattern }) => {
    const ticket = getters.currentTicket

    const index = ticket.items.findIndex(ticketItem =>
      itemsAreEqual({ ...item, color, pattern }, ticketItem),
    )

    const itemFound = index !== -1 ? ticket.items[index] : null

    return { itemFound, index }
  },
}

function calculateDueDate(ticket) {
  if (isNullOrUndefined(ticket.service))
    return ticket.dropped_at
  if (!isNullOrUndefined(ticket.due_date))
    return ticket.due_date
  if (!isNullOrUndefined(ticket.dropped_at))
    return ''

  const dues = Object.values(ticket.service.locations) // TODO: change to replace one due, of the location
  const droppedAt = moment(ticket.dropped_at)
  const configDroppedAt = moment(dues[0].dropped_at, 'HH:mm') // TODO: change to use actual dropped config
  const dueTime = moment(dues[0].due_time, 'HH:mm') // TODO: change to use actual due config

  if (droppedAt.isAfter(configDroppedAt))
    dueTime.add(1, 'days')
  dueTime.add(dues[0].duration, 'days') // TODO: change to use actual due config

  return dueTime
}

function itemsAreEqual(itemA, itemB) {
  if (itemA.id !== itemB.id)
    return false
  if (itemA.color !== itemB.color)
    return false
  if (itemA.pattern !== itemB.pattern)
    return false

  if (itemA.addon && itemB.addon) {
    if (itemA.addon.id !== itemB.addon.id)
      return false
  }
  else if (itemA.addon && !itemB.addon) {
    return false
  }
  else if (!itemA.addon && itemB.addon) {
    return false
  }

  return true
}
